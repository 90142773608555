import { isRequestCanceled } from '@/utils/api'
import price from '@/mixins/common/price'
import Gleap from 'gleap'

const FREE = 'free'
const FREE_TRIAL = 'free_trial'
const EARLY_BIRD = 'early_bird'
const BASIC = 'basic'
const PRO = 'pro'
const EXPERT = 'expert'
const APP_SUMO_TIER_1 = 'trafft_tier1'
const APP_SUMO_TIER_2 = 'trafft_tier2'
const FREE_V2 = 'free_v2'
const BASIC_V2 = 'basic_v2';
const PRO_V2 = 'pro_v2';
const EXPERT_V2 = 'expert_v2';
const STARTER = 'starter';
const SCALING = 'scaling';
const ENTERPRISE = 'enterprise';
const STARTER_V2 = 'starter_v2';
const SCALING_V2 = 'scaling_v2';

export default {
  FREE,
  FREE_TRIAL,
  EARLY_BIRD,
  BASIC,
  PRO,
  EXPERT,
  APP_SUMO_TIER_1,
  APP_SUMO_TIER_2,
  FREE_V2,
  BASIC_V2,
  PRO_V2,
  EXPERT_V2,
  STARTER,
  SCALING,
  ENTERPRISE,
  STARTER_V2,
  SCALING_V2,

  data: function () {
    return {
      plans: {
        [FREE]: { label: this.$t('free'), icon: 'free.svg' },
        [FREE_V2]: { label: this.$t('free'), icon: 'free.svg' },
        [FREE_TRIAL]: { label: this.$t('free_trial'), icon: 'free_trial.svg' },
        [EARLY_BIRD]: { label: this.$t('early_bird'), icon: 'early_bird.svg' },
        [BASIC]: { label: this.$t('basic'), icon: 'basic.svg' },
        [BASIC_V2]: { label: this.$t('basic'), icon: 'basic.svg' },
        [PRO]: { label: this.$t('pro'), icon: 'pro.svg' },
        [PRO_V2]: { label: this.$t('pro'), icon: 'pro.svg' },
        [EXPERT]: { label: this.$t('expert'), icon: 'expert.svg' },
        [EXPERT_V2]: { label: this.$t('expert'), icon: 'expert.svg' },
        [APP_SUMO_TIER_1]: { label: this.$t('app_sumo_tier_1'), icon: 'trafft_tier1.svg' },
        [APP_SUMO_TIER_2]: { label: this.$t('app_sumo_tier_2'), icon: 'trafft_tier2.svg' },
        [STARTER]: { label: this.$t('starter'), icon: 'starter.svg' },
        [SCALING]: { label: this.$t('scaling'), icon: 'scaling.svg' },
        [ENTERPRISE]: { label: this.$t('enterprise'), icon: 'enterprise.svg' },
        [STARTER_V2]: { label: this.$t('starter'), icon: 'starter.svg' },
        [SCALING_V2]: { label: this.$t('scaling'), icon: 'scaling.svg' },
      },
    }
  },

  mixins: [price],

  methods: {
    getPlanLabel (plan) {
      return plan.label
    },

    getPlanIcon (plan) {
      return require(`@/assets/img/billing/plans/${ this.plans[plan.name].icon }`)
    },

    getIncludesTitle (plan) {
      if (plan.name === 'free_v2') {
        return this.$t('free_forever_features')
      }

      if (plan.name === 'starter_v2') {
        return this.$t('all_free_forever_plus')
      }

      if (plan.name === 'scaling_v2') {
        return this.$t('all_starter_plus')
      }

      if (plan.name === 'enterprise') {
        return this.$t('all_scaling_plus')
      }

      return ''
    },

    getIncludesForPlanChange (plan) {
      let numberOfEmployees = plan.planNumberOfEmployees ? plan.planNumberOfEmployees : plan.maxNumberOfEmployees

      let includes = [
        (numberOfEmployees > 1 ? this.$t('starting_at') + ' ' + numberOfEmployees : numberOfEmployees) + ' ' + (numberOfEmployees === 1 ? this.$t('seats') : this.$t('seats')),
        `${ plan.maxNumberOfCustomFeatures ? plan.maxNumberOfCustomFeatures : this.$t('unlimited') } ${ this.$t('advanced') } ${ (plan.maxNumberOfCustomFeatures === 1 ? this.$t('feature_or_integration') : this.$t('features_and_integrations') ).toLowerCase() }`,
        (plan.name === 'free_v2' ? this.$t('200_emails_per_month') : this.$t('unlimited_emails')),
      ]

      if (['starter_v2', 'scaling_v2'].includes(plan.name)) {
        const smsCredits = {starter_v2: 5, scaling_v2: 10 }

        includes.push(`${ smsCredits[plan.name] } ${ this.$t('sms_credits') } / ${ this.$t('month') }`)
      }

      return includes
    },

    getIncludes (plan) {
      const smsCredits = {starter_v2: 5, scaling_v2: 10 }

      if (plan.name === 'enterprise') {
        return [
          this.$t('variable_seat_pricing'),
          this.$t('hosted_server'),
          this.$t('dedicated_manager'),
          this.$t('assistance_onboarding'),
          this.$t('and_many_more'),
        ]
      }

      let userPriceRow = ''
      if (plan.priceForUser) {
         userPriceRow = `${this.formatPlanPrice(plan.priceForUser.price)}${plan.priceForUser.isThereATax ? '+'+this.$t('vat').toUpperCase() : ''} ${this.$t('additional_user')}`
      }

      if (plan.name === 'free_v2') {
        return [
          userPriceRow,
          this.$t('unlimited_appointments'),
          `${plan.maxNumberOfCustomFeatures} ${this.$t('custom_features')}`,
          this.$t('one_location'),
          this.$t('200_emails_per_month'),
          this.$t('sms_notifications'),
          this.$t('booking_website'),
          this.$t('google_outlook_calendar'),
          this.$t('special_days'),
          this.$t('group_booking'),
          this.$t('coupons'),
        ]
      }

      if (plan.name === 'starter_v2') {
        return [
          userPriceRow,
          `${plan.maxNumberOfCustomFeatures} ${this.$t('custom_features')}`,
          this.$t('unlimited_locations'),
          this.$t('unlimited_emails'),
          `${smsCredits[plan.name]} ${this.$t('sms_credits_month')}`,
          this.$t('accept_payments'),
          this.$t('branded_invoices'),
          this.$t('and_many_more'),
        ]
      }

      if (plan.name === 'scaling_v2') {
        return [
          userPriceRow,
          this.$t('unlimited_custom_features'),
          this.$t('recurring_appointments'),
          `${smsCredits[plan.name]} ${this.$t('sms_credits_month')}`,
          this.$t('custom_domain'),
          this.$t('branding_removal'),
          this.$t('webhooks'),
          this.$t('zapier'),
          this.$t('whatsapp_messaging'),
          this.$t('and_many_more'),
        ]
      }

      return []
    },

    getPlanCoupons (plan) {
      let coupons = null
      // if (plan.billingInterval === 'monthly') {
      //   coupons = {
      //     'starter_v2': { code: 'BLACK_FRIDAY_40_2024', value: 0.4 },
      //     'scaling_v2': { code: 'BLACK_FRIDAY_50_2024', value: 0.5 },
      //   }
      // }
      //
      // if (plan.billingInterval === 'yearly') {
      //   coupons = {
      //     'starter_v2': { code: 'BLACK_FRIDAY_50_2024', value: 0.5 },
      //     'scaling_v2': { code: 'BLACK_FRIDAY_60_2024', value: 0.6 },
      //   }
      // }

      return ['free', 'free_v2', 'free_trial'].includes(this.tenantInfo.plan.name) ? coupons?.[plan.name] : null
    },

    getCouponDiscountValue (plan) {
      let discount = 0

      if (plan.billingInterval === 'monthly') {
        if (plan.name === 'starter_v2') {
          discount = 40
        }

        if (plan.name === 'scaling_v2') {
          discount = 50
        }
      }

      if (plan.billingInterval === 'yearly') {
        if (plan.name === 'starter_v2') {
          discount = 50
        }

        if (plan.name === 'scaling_v2') {
          discount = 60
        }
      }

      return discount + '%' + ' off'
    },

    getPlanSavings (plan) {
      switch (plan) {
        case 'starter_v2':
          return this.$t('save') + ' ' + 3 + ' ' + this.$t('months');
        case 'scaling_v2':
          return this.$t('save') + ' ' + 4 + ' ' + this.$t('months');
        default:
          return ''
      }
    },

    getPaddleProductId (plan) {
      if (plan.isSmsPlan) {
        return plan.paddleId
      }

      return plan.paddlePlanId
    },

    async saveSelectedPlan () {
      await this.$http.post(
        '/api/v1/backoffice/plans/select',
        { itemsToDeactivate: this.itemsToDeactivate }
      )

      this.$store.commit('billing/setIsCheckoutInProgress', true)
      this.$store.commit('billing/setVisibleExpireAlert', false)
      this.$store.commit('billing/setWasSeenExpireAlert', true)

      if (this.selectedPlan.name === 'free_v2') {
        this.$emit('change-step', 'free-chosen')
        await this.getTenantInfo()
      }

      this.$store.commit('billing/setIsCheckoutInProgress', false)
    },

    async getTenantInfo () {
      try {
        this.$store.commit('view/showLoading')

        const response = await this.$http.get(
          '/api/v1/backoffice/tenant-info'
        )

        if (response.status !== 200) {
          this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
          return
        }

        this.$store.commit('billing/setTenantInfo', response.data.tenantInfo)
        this.$store.commit('features/setPlanAvailableFeatures', response.data.planAvailableFeatures)
      } catch (e) {
        if (isRequestCanceled(e)) return
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      } finally {
        this.$store.dispatch('view/hideLoading')
      }
    },


    contactUs () {
      this.$store.commit('billing/setVisibleDialogBilling', false)

      Gleap.startConversation(true)
      Gleap.setTicketAttribute('note', 'User requested an Enterprise plan')
    }
  }
}
