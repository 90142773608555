<template>
  <el-form class="cancel-plan-form">
    <adm-radio-group v-model="form.reason" class="cancel-plan-form__radio-group">
      <adm-radio
        v-for="option in options"
        :key="option.key"
        :label="option.key"
        :value="option.key"
      >
        {{ option.text }}
      </adm-radio>
    </adm-radio-group>

    <adm-form-item :label="$t('optional_comment')">
      <adm-input
        v-model="form.comment"
        :rows="4"
        :maxlength="1000"
        type="textarea"
      />
    </adm-form-item>
  </el-form>
</template>

<script>
import AdmRadioGroup from '@/views/_components/radio/AdmRadioGroup.vue'
import AdmInput from '@/views/_components/input/AdmInput.vue'
import AdmFormItem from '@/views/_components/form/AdmFormItem.vue'
import AdmRadio from '@/views/_components/radio/AdmRadio.vue'

export default {
  name: 'CancelPlanForm',
  components: { AdmRadio, AdmFormItem, AdmInput, AdmRadioGroup },
  data () {
    return {
      form: {
        reason: '',
        comment: ''
      },
      options: [
        { key: 'missing_key_features', text: this.$t('Missing key features') },
        { key: 'complicated_to_use', text: this.$t('Complicated to use') },
        { key: 'switching_to_another_tool', text: this.$t('Switching to another tool') },
        { key: 'too_expensive', text: this.$t('Too expensive') },
        { key: 'technical_issues', text: this.$t('Technical issues') },
        { key: 'poor_customer_support', text: this.$t('Poor customer support') },
        { key: 'no_longer_needed', text: this.$t('No longer needed') },
        { key: 'other', text: this.$t('Other') }
      ]
    }
  },
  methods: {
    getFormData() {
      return {
        reason: this.form.reason,
        comment: this.form.comment
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cancel-plan-form {
  &__radio-group {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $shade-300;
  }
}
</style> 