import en from 'element-ui/lib/locale/lang/en'
import es from 'element-ui/lib/locale/lang/es'
import cs from 'element-ui/lib/locale/lang/cs-CZ'
import ptbr from 'element-ui/lib/locale/lang/pt-br'
import fr from 'element-ui/lib/locale/lang/fr'
import de from 'element-ui/lib/locale/lang/de'
import sr from './assets/element-sr-Latn'
import ru from 'element-ui/lib/locale/lang/ru-RU'
import th from 'element-ui/lib/locale/lang/th'
import sv from 'element-ui/lib/locale/lang/sv-SE'
import it from 'element-ui/lib/locale/lang/it'
import nl from 'element-ui/lib/locale/lang/nl'
import tr from 'element-ui/lib/locale/lang/tr-TR'
import ja from 'element-ui/lib/locale/lang/ja'
import pl from 'element-ui/lib/locale/lang/pl'

export default {
  en,
  es,
  cs,
  fr,
  de,
  'sr-Latn': sr,
  'pt-BR': ptbr,
  'ru-RU': ru,
  th,
  sv,
  it,
  nl,
  tr,
  ja,
  pl,
}
