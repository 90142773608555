<template>
  <a
    class="upgrade-button"
    href="javascript:void(0)"
    @click="handleClick"
  >
    <span class="upgrade-button__icon-wrapper">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="share">
          <path id="Vector" d="M20.0483 11.7612L9.54833 23.0112C9.43699 23.1305 9.28978 23.2103 9.12903 23.2383C8.96829 23.2664 8.80277 23.2413 8.65759 23.1668C8.51242 23.0922 8.3955 22.9724 8.32457 22.8255C8.25365 22.6785 8.23258 22.5124 8.26458 22.3524L9.63952 15.4778L4.23667 13.4518C4.12121 13.4085 4.01821 13.3374 3.93679 13.2448C3.85536 13.1522 3.79802 13.0409 3.76984 12.9209C3.74167 12.8009 3.74352 12.6757 3.77524 12.5566C3.80697 12.4374 3.86758 12.3279 3.95171 12.2378L14.4517 0.987767C14.5631 0.868477 14.7103 0.788754 14.871 0.760689C15.0318 0.732624 15.1973 0.757748 15.3424 0.832252C15.4876 0.906755 15.6045 1.02657 15.6755 1.17353C15.7464 1.32049 15.7675 1.48657 15.7355 1.64658L14.3605 8.52118L19.7634 10.5472C19.8788 10.5905 19.9818 10.6616 20.0633 10.7542C20.1447 10.8468 20.202 10.9581 20.2302 11.0781C20.2584 11.1981 20.2565 11.3233 20.2248 11.4424C20.1931 11.5616 20.1325 11.6711 20.0483 11.7612Z" fill="#FFA600" />
        </g>
      </svg>
    </span>
    <span class="upgrade-button__text">
      {{ $t('upgrade') }}
    </span>
  </a>
</template>

<script>
export default {
  name: 'UpgradeButton',

  methods: {
    handleClick () {
      this.$store.commit('billing/setVisibleDialogBilling', true)
    },
  }
}
</script>

<style lang="scss" scoped>
.upgrade-button {
  display: none;
  font-weight: 500;
  border-radius: 7px;
  outline: none;
  align-items: center;
  justify-content: center;
  -webkit-transition: background 0.15s;
  transition: background 0.15s;
  border: 1px solid #FFA600;
  color: $shade-900;
  background: rgba(255, 166, 0, 0.10);
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  width: 36px;
  padding: 0;
  flex-shrink: 0;
  gap: 6px;
  margin-right: 16px;

  @media (min-width: 377px) {
    display: inline-flex;
  }

  @media (min-width: 1025px) {
    width: auto;
    padding: 0 12px;
    flex-shrink: unset;
    margin-right: 24px;
  }

  &:hover {
    background: rgba(255, 166, 0, 0.20);
    text-decoration: none;
  }

  &:focus:not(:active) {
    background-color: rgba(255, 166, 0, 0.30);
    border: 2px solid #FFA600;
    box-shadow: 0 0 0 2px  #FFA600;
  }

  &:active {
    background: rgba(255, 166, 0, 0.30);
  }

  &__text {
    display: none;

    @media (min-width: 1025px) {
      display: inline-block;
    }
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    svg {
      margin-right: 0;

      path {
        fill: #FFA600;
      }
    }
  }
}
</style>
