<template>

  <!-- Billing Dialog Free Downgrade -->
  <div class="ad-billing-dialog-free-downgrade">
    <div class="ad-billing-dialog-free-downgrade__body-free">
      <div class="ad-billing-dialog-free-downgrade__body-free__title">
        {{ $t('why_do_you_want_to_cancel') }}
      </div>
      <cancel-plan-form ref="form" />

      <div class="ad-billing-dialog-free-downgrade__body-free__actions">

        <!-- Cancel -->
        <adm-button @click="onCancel">
          {{ $t('dismiss') }}
        </adm-button>
        <!-- /Cancel -->

        <!-- Downgrade -->
        <adm-button color="red" :loading="loading" @click="onDowngrade">
          {{ $t('cancel_plan') }}
        </adm-button>
      </div>
    </div>

    <billing-footer />
  </div>
  <!-- /Billing Dialog Free Downgrade -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import { mapState } from 'vuex'
import BillingFooter from '@/views/Dashboard/_components/BillingFooter.vue'
import CancelPlanForm from '@/views/Billing/_components/billingPageHeader/CancelPlanForm.vue'
import mixinPlans from '@/mixins/plans/plans'

export default {
  name: 'BillingDialogFreeDowngrade',

  components: {
    CancelPlanForm,
    BillingFooter,
    AdmButton
  },

  mixins: [mixinPlans],

  data () {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      tenantInfo: state => state.billing.tenantInfo
    }),
  },

  methods: {
    onCancel () {
      this.$emit('change-step', 'plans')
    },

    async onDowngrade () {
      this.loading = true
      await this.downgradeToFree()
      this.loading = false
    },

    async downgradeToFree () {
      try {
        let formData = {
          reason: '',
          comment: '',
        }

        if (this.$refs.form) {
          formData = this.$refs.form.getFormData()
        }

        await this.$http.post(
          '/api/v1/backoffice/plans/cancel',
          {
            reason: formData.reason,
            comment: formData.comment,
          }
        )

        this.$store.commit('billing/setIsCheckoutInProgress', true)
        this.getTenantInfo()
        // TODO - Check if there is a better way to refresh the purchased plan (probably with websocket)
        setTimeout(() => {
          this.$store.commit('billing/setReloadPlan', true)
        }, 5000)

        this.$emit('change-step', 'free-chosen')
      } catch (e) {
        if (e.response.status === 500) {
          return this.$message({ message: e.response.data.message, type: 'error', showClose: true })
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.ad-billing-dialog-free-downgrade {
  width: 368px;
  background: $adm-white;
  margin: 1.25rem auto 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  &__body-free {
    padding: 20px 20px 0;

    &__title {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 20px;
    }

    &__actions {
      display: flex;
      justify-content: right;
      margin-bottom: 1.5rem;
      gap: 16px;
      flex-wrap: wrap;
    }
  }
}
</style>
