export default [
  {
    path: 'settings',
    name: 'settings',
    redirect: 'settings/general',
    meta: {
      title: 'settings',
      requiresAuth: true,
      requiredPermissions: 'edit_settings'
    },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsPage').then(value => value.default),
    children: [
      // @TODO remove redirect after couple of months
      {
        path: 'payment-settings',
        name: 'settings-payment-settings-redirect',
        redirect: '/finance/payment-settings',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'general',
        name: 'settings-general',
        meta: {
          title: 'general_settings',
          breadcrumb: 'General',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/General/GeneralSettings').then(value => value.default)
      },
      {
        path: 'sms-notifications/history',
        name: 'settings-sms-notifications-history',
        meta: {
          title: 'sms_notifications_history',
          breadcrumb: 'sms_notifications',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SmsNotifications/History/SmsNotificationsHistory').then(value => value.default)
      },
      {
        path: 'sms-notifications/templates',
        name: 'settings-sms-notifications-templates',
        meta: {
          title: 'sms_notifications_templates',
          breadcrumb: 'sms_notifications',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SmsNotifications/Templates/SmsNotificationsTemplates').then(value => value.default)
      },
      {
        path: 'sms-notifications/settings',
        name: 'settings-sms-notifications-settings',
        meta: {
          title: 'sms_notifications_settings',
          breadcrumb: 'sms_notifications',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SmsNotifications/Settings/SmsNotificationsSettings').then(value => value.default)
      },
      {
        path: 'email-notifications/templates',
        name: 'settings-email-notifications-templates',
        meta: {
          title: 'email_notifications_templates',
          breadcrumb: 'email_notifications',
          requiresAuth: true,
          requiredPermissions: 'edit_settings',
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/EmailNotifications/EmailNotificationsTemplates').then(value => value.default)
      },
      {
        path: 'email-notifications/settings',
        name: 'settings-email-notifications-settings',
        meta: {
          title: 'email_notifications_settings',
          breadcrumb: 'email_notifications',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/EmailNotifications/Settings/EmailNotificationsSettings').then(value => value.default)
      },
      {
        path: 'whatsapp-notifications/templates',
        name: 'settings-whatsapp-notifications-templates',
        meta: {
          title: 'whatsapp_notifications_templates',
          breadcrumb: 'whatsapp_notifications',
          requiresAuth: true,
          requiredPermissions: 'edit_settings',
          feature: 'whatsapp_integration',
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/WhatsappNotifications/Templates/WhatsappNotificationsTemplates').then(value => value.default)
      },
      {
        path: 'whatsapp-notifications/settings',
        name: 'configure-whatsapp',
        meta: {
          title: 'whatsapp_notifications_settings',
          breadcrumb: 'whatsapp_notifications',
          requiresAuth: true,
          requiredPermissions: 'edit_settings',
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/WhatsappNotifications/Settings/WhatsappNotificationsSettings').then(value => value.default)
      },
      {
        path: 'company',
        name: 'settings-company',
        meta: {
          title: 'company_settings',
          breadcrumb: 'company',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Company/CompanySettings').then(value => value.default)
      },
      {
        path: 'office-hours',
        name: 'office-hours',
        meta: {
          title: 'office_hours',
          breadcrumb: 'office_hours',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/OfficeHours/OfficeHours').then(value => value.default)
      },
      {
        path: 'company-days-off',
        name: 'company-days-off',
        meta: {
          title: 'company_days_off',
          breadcrumb: 'company_days_off',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/CompanyDaysOff/CompanyDaysOff').then(value => value.default)
      },
      {
        path: 'users-and-roles',
        name: 'settings-users',
        meta: {
          title: 'users_and_roles',
          breadcrumb: 'users_and_roles',
          requiresAuth: true,
          requiredPermissions: 'edit_settings',
          planFeature: 'users_and_roles_page',
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Users&Roles/usersList/UsersList').then(value => value.default)
      },
      {
        path: 'users-and-roles/roles',
        name: 'settings-roles',
        meta: {
          title: 'users_and_roles',
          breadcrumb: 'users_and_roles',
          requiresAuth: true,
          requiredPermissions: 'edit_settings',
          planFeature: 'users_and_roles_page',
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Users&Roles/rolesList/RolesList').then(value => value.default)
      },
      {
        path: 'users-and-roles/roles/add',
        name: 'role-add',
        meta: {
          title: 'add_role',
          breadcrumb: 'users_and_roles',
          requiresAuth: true,
          requiredPermissions: 'edit_settings',
          feature: 'custom_roles',
          planFeature: 'custom_roles',
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Users&Roles/manageRole/ManageRole').then(value => value.default)
      },
      {
        path: 'users-and-roles/roles/:roleId(\\d+)',
        name: 'role-edit',
        meta: {
          title: 'edit_role',
          breadcrumb: 'users_and_roles',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Users&Roles/manageRole/ManageRole').then(value => value.default)
      }
    ]
  }
]
