<template>
  <div class="billing-footer">
    <span class="billing-footer__trafft">
      © Trafft {{ $moment().format('Y') }}
    </span>

    <!-- Links -->
    <span class="billing-footer__links">
      <a href="https://trafft.com/terms-of-service/" target="_blank">
        {{ $t('terms_of_service') }}
      </a>
      <span>
        -
      </span>
      <a @click="openBeacon()">
        {{ $t('contact_us') }}
      </a>
    </span>
    <!-- /Links -->
  </div>
</template>

<script>
import Gleap from 'gleap'

export default {
  name: 'BillingFooter',

  methods: {
    openBeacon () {
      if (Gleap.isOpened()) {
        Gleap.close()

        return
      }
      Gleap.startConversation(true)
    }
  }
}
</script>

<style scoped lang="scss">
.billing-footer {
  border-top: 1px solid $shade-300;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__trafft {
    font-weight: normal;
    font-size: 14px;
    color: $shade-500;
    margin-bottom: 0.5rem;
  }

  &__links {
    font-weight: normal;
    display: flex;

    span {
      text-align: center;
      color: $shade-500;
      margin: 0 0.75rem;
    }
  }
}
</style>