export default {
  data () {
    return {
      defaultCoordinates: {
        lat: 40.748817,
        lng: -73.985428
      },
      googleMap: {
        libraries: ['places'],
        version: 'weekly'
      }
    }
  },

  methods: {
    async deleteLocation (location) {
      try {
        let responseDelete = null

        const responseEffect = await this.$http.get(
          '/api/v1/locations/effect/' + location.id
        )

        if (responseEffect.status !== 200 || responseEffect.data.status === 'error') {
          return this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        }

        const message = this.generateDeleteLocationMessage(responseEffect.data.payload.count)

        await this.$admConfirm.show(
          this.$t('delete_location'),
          this.$t('delete_sure_question_location') + ' ' + message,
          {
            confirmButtonText: message ? this.$t('delete_anyway') : this.$t('delete'),
          }
        ).then(async () => {
          responseDelete = await this.$http.delete(
            '/api/v1/locations/' + location.id,
          )
          if (responseDelete.status === 200 && this.$route.params.locationId) {
            await this.$router.push({ name: 'locations' })
          }
        }).catch((e) => {
          if (e.response.status !== 200 || e.response.data.status === 'error') {
            return this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
          }
        })

        return responseDelete
      } catch (e) {}
    },

    generateDeleteLocationMessage (count) {
      if (count.future > 0 && count.past === 0) {
        return count.future + ' ' + this.$t('future') + ' ' +
          (count.future > 1 ? this.$t('bookings_l') : this.$t('booking_l')) + ' ' +
          this.$t('delete_location_warning')
      }

      if (count.future === 0 && count.past > 0) {
        return count.past + ' ' + this.$t('past') + ' ' +
          (count.past > 1 ? this.$t('bookings_l') : this.$t('booking_l')) + ' ' +
          this.$t('delete_location_warning')
      }

      if (count.future > 0 && count.past > 0) {
        return count.future + ' ' + this.$t('future') + ' ' + this.$t('and') + ' ' +
          count.past + ' ' + this.$t('past') + ' ' + this.$t('bookings_l') + ' ' +
          this.$t('delete_location_warning')
      }

      return ''
    },

    async toggleLocationStatus (location) {
      try {
        this.toggleStatusButtonLoading = true
        const response = await this.$http.post(
          '/api/v1/locations/status/' + location.id,
          {},
          { signal: this.cancelToken.signal }
        )

        if (response.status !== 200) {
          this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        }

        location.status = location.status === 1 ? 2 : 1
        this.$message({ message: response.data.message, type: 'success', showClose: true })
      } catch (e) {
        if (e.response.status === 409) {
          this.$store.commit('billing/setVisibleLimitReachedDialog', true)
          this.$store.commit('billing/setLimitReachedDialogEntity', 'location')
          this.$store.commit('billing/setLimitReachedDialogTitle', this.$t('location_limit_reached_title'))
          this.$store.commit(
            'billing/setLimitReachedDialogText',
            this.$store.getters['features/isFeatureVisible']('multiple_locations') ?
              e.response.data.message :
              this.$t('location_limit_reached_text_agency')
          )
          return
        }

        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      } finally {
        this.toggleStatusButtonLoading = false
      }
    }
  }
}
