<template>

  <!-- Link Share Section -->
  <div class="share-appointment">
    <div v-if="isShoplazza()">
      <p class="mt-6 mb-12">
        {{ $t('copy_the_code_and_paste_it') }}
      </p>
      <ol class="ml-32 mb-12">
        <li>{{ $t('copy_the_code') }}</li>
        <li>{{ $t('log_into_your_shoplazza_admin_dashboard') }}</li>
        <li>{{ $t('click_on_online_store_this_will_expand_a_submenu') }}</li>
        <li>{{ $t('in_the_submenu_select_custom_page_to_manage_your_website_pages') }}</li>
        <li>{{ $t('create_or_edit_existing_page') }}</li>
        <li>{{ $t('click_on_the_icon_to_open_the_html_editor_this_allows_you_to_add_your_own_custom_code') }}</li>
        <li>{{ $t('paste_the_code_and_save_the_page') }}</li>
        <li>{{ $t('to_ensure_that_the_custom_code_works_as_expected_click_on_the_view_button') }}</li>
      </ol>

      <p class="mb-12">
        {{ $t('once_you_added_the_code_into_the_page_you_can_click_done_to_close_this_window') }}
      </p>
    </div>

    <el-form>
      <template v-if="$store.state.shareBookingForm.selectedShareOption.type === 'socialMedia'">
        <social-media-story :link="link" :uuid="uuid" />
      </template>

      <template v-if="$store.state.shareBookingForm.selectedShareOption.type === 'embed'">
        <!-- Iframe Input -->
        <adm-form-item class="share-appointment__iframe" :label="$t('embed_iframe')">
          <adm-input
            :value="getEmbedCode()"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 9 }"
            readonly
            resize="none"
            class="mb-20"
          />

          <!-- Copy Code -->
          <adm-button size="micro" type="borderless" icon-start="copy" @click="copyIframeCodeToClipboard">
            {{ $t('copy_code_to_clipboard') }}
          </adm-button>
          <!-- /Copy Code -->

        </adm-form-item>
        <!-- Iframe Input -->
      </template>

      <template v-if="$store.state.shareBookingForm.selectedShareOption.type === 'email'">
        <div class="share-appointment__email">
          <div class="share-appointment__email__title">
            {{ $t('email_preview') }}
          </div>
          <div class="share-appointment__email__container">
            <email-html-content id="email-html-content" :link="link" />
          </div>

          <!-- Copy Code -->
          <adm-button size="micro" type="borderless" icon-start="copy" @click="copyEmailHtmlContent()">
            {{ $t('copy_to_clipboard') }}
          </adm-button>
          <!-- /Copy Code -->
        </div>
      </template>

      <template v-if="$store.state.shareBookingForm.selectedShareOption.type === 'link'">
        <!-- Link Input -->
        <adm-form-item :label="$t('link')">
          <adm-input
            :value="link"
            autocomplete="off"
          >
            <div
              slot="suffix"
              class="share-appointment__copy-link-button"
              @click="copyLink(link)"
            >
              <adm-icon icon="copy" />
            </div>
          </adm-input>
        </adm-form-item>
        <!-- /Link Input -->

        <!-- Short Link Input -->
        <adm-form-item :label="$t('short_link')">
          <adm-input
            v-if="shortLink !== null"
            :value="shortLink"
            autocomplete="off"
          >
            <div
              slot="suffix"
              class="share-appointment__copy-link-button"
              @click="copyLink(shortLink)"
            >
              <adm-icon icon="copy" />
            </div>
          </adm-input>
          <adm-skeleton-element v-else :height="40" />
        </adm-form-item>
        <!-- /Short Link Input -->

        <!-- Set Link To Expire -->
        <adm-form-item class="mb-24">
          <adm-checkbox
            v-model="isOneTimeLink"
            :disabled="disabledOneTimeLink"
            @change="setIsOneTimeLink"
          >
            {{ $t('share_link_expire') }}
          </adm-checkbox>
        </adm-form-item>
        <!-- /Set Link To Expire -->

        <!-- Share Link -->
        <adm-form-item class="share-appointment__share-buttons">

          <!-- Title -->
          <div class="share-appointment__share-buttons__title">
            {{ $t('share_this_link_with_your_customers') }}
          </div>
          <!-- /Title -->

          <!-- Buttons -->
          <div class="share-appointment__share-buttons__buttons">

            <!-- Facebook -->
            <adm-button
              size="mini"
              type="borderless"
              color="dark"
              icon-start="email"
              @click="shareViaFacebook"
            >
              <template #icon-start>
                <img src="@/assets/img/socials/medium/facebook.svg" alt="">
              </template>
              {{ $t('facebook') }}
            </adm-button>
            <!-- /Facebook -->

            <!-- X -->
            <adm-button
              size="mini"
              type="borderless"
              color="dark"
              icon-start="email"
              @click="shareViaX"
            >
              <template #icon-start>
                <img src="@/assets/img/socials/medium/x.svg" alt="">
              </template>
              {{ $t('x') }}
            </adm-button>
            <!-- /X -->

            <!-- Linkedin -->
            <adm-button
              size="mini"
              type="borderless"
              color="dark"
              icon-start="email"
              @click="shareViaLinkedin"
            >
              <template #icon-start>
                <img src="@/assets/img/socials/medium/linkedin.svg" alt="">
              </template>
              {{ $t('linkedin') }}
            </adm-button>
            <!-- /Linkedin -->

            <!-- Email -->
            <adm-button
              size="mini"
              type="borderless"
              color="dark"
              icon-start="email"
              @click="shareViaEmail"
            >
              {{ $t('email') }}
            </adm-button>
            <!-- /Email -->

          </div>
          <!-- /Buttons -->

        </adm-form-item>
        <!-- /Share Link -->
      </template>

      <template v-if="$store.state.shareBookingForm.selectedShareOption.type === 'qrCode'">
        <div class="share-appointment__qr-code__content">
          <span class="share-appointment__qr-code__content__title">{{ $t('qr_code') }}</span>
          <div class="share-appointment__qr-code__content__generated-code">
            <img :src="qrCodeUri.uri" alt="">
          </div>
          <div class="share-appointment__qr-code__content__actions">
            <adm-button size="micro" type="borderless" color="dark" icon-start="copy" @click="copyQRCodeLink">
              {{ $t('copy_qr_code_link') }}
            </adm-button>
            <adm-button size="micro" type="borderless" color="dark" icon-start="download" @click="downloadQRCodeImage">
              {{ $t('download_qr_code_image') }}
            </adm-button>
          </div>
        </div>
      </template>

    </el-form>

    <!-- Appointment Details -->
    <div v-if="isAppointmentsDetailsVisible()" class="share-appointment__details">
      <div class="share-appointment__details__label">
        {{ $t('appointment_details') }}
      </div>

      <div class="share-appointment__details__container">
        <appointment-details-item
          v-if="$store.state.shareBookingForm.queryParams.serviceCategory"
          icon="category"
          :title="$t('service_category')"
        >
          {{ $store.state.shareBookingForm.queryParams.serviceCategory.name }}
        </appointment-details-item>
        <appointment-details-item
          v-if="$store.state.shareBookingForm.queryParams.service"
          icon="services"
          :title="$t('service')"
        >
          {{ $store.state.shareBookingForm.queryParams.service.name }}
        </appointment-details-item>
        <appointment-details-item
          v-if="$store.state.shareBookingForm.queryParams.employee"
          icon="employee"
          :title="$t('employee')"
        >
          {{ $store.state.shareBookingForm.queryParams.employee.firstName }}
          {{ $store.state.shareBookingForm.queryParams.employee.lastName }}
        </appointment-details-item>
        <appointment-details-item
          v-if="$store.state.shareBookingForm.queryParams.location"
          icon="locations"
          :title="$t('location')"
        >
          {{ $store.state.shareBookingForm.queryParams.location.name }}
        </appointment-details-item>
        <appointment-details-item
          v-if="$store.state.shareBookingForm.queryParams.extras.length"
          icon="extras"
          :title="$t('extras')"
        >
          <div
            v-for="extra in $store.state.shareBookingForm.queryParams.extras"
            :key="extra.id"
            class="share-appointment__details__container__extra"
          >
            <div class="share-appointment__details__container__extra__item">
              {{ extra.name }}
            </div>
            <adm-badge v-if="extra.quantity > 1" type="primary">
              x{{ extra.quantity }}
            </adm-badge>
          </div>
        </appointment-details-item>
        <appointment-details-item
          v-if="$store.state.shareBookingForm.queryParams.dateRange"
          icon="date"
          :title="$t('date_range')"
        >
          {{ getFormattedDateRangeLabel($store.state.shareBookingForm.queryParams.dateRange) }}
        </appointment-details-item>
        <appointment-details-item
          v-if="Object.keys($store.getters['shareBookingForm/getTimeslotDates']()).length > 0"
          icon="date"
          :title="$t('timeslots')"
        >
          <div v-for="(slots, dateIndex) in $store.getters['shareBookingForm/getTimeslotDates']()" :key="dateIndex">
            <div class="share-appointment__details__container__date">
              {{ formatDate(dateIndex) }}
            </div>
            <div class="share-appointment__details__container__timeslots">
              <adm-tag v-for="slot in slots" :key="`${dateIndex}_${slot}`" type="primary">
                {{ formatTime(slot) }}
              </adm-tag>
            </div>
          </div>
        </appointment-details-item>
      </div>
    </div>
    <!-- /Appointment Details -->

  </div>
  <!-- /Link Share Section -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmFormItem from '@/views/_components/form/AdmFormItem'
import AdmIcon from '@/views/_components/icon/AdmIcon'
import AdmInput from '@/views/_components/input/AdmInput'
import { copy, copyElementToClipboard } from '@/utils/copy'
import { getIframeCode } from '@/utils/url'
import AppointmentDetailsItem
  from '@/views/Dashboard/ShareBookingFormDialog/LinkSection/_components/AppointmentDetailsItem.vue'
import service from '@/mixins/page/service'
import AdmBadge from '@/views/_components/badge/AdmBadge.vue'
import datetime from '@/mixins/common/datetime'
import AdmTag from '@/views/_components/tag/AdmTag.vue'
import EmailHtmlContent
  from '@/views/Dashboard/ShareBookingFormDialog/LinkSection/EmailHtmlContent/EmailHtmlContent.vue'
import SocialMediaStory
  from '@/views/Dashboard/ShareBookingFormDialog/LinkSection/SocialMedia/SocialMediaStory/SocialMediaStory.vue'
import AdmCheckbox from '@/views/_components/checkbox/AdmCheckbox.vue'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement.vue'

export default {
  name: 'LinkShareSection',

  components: {
    AdmSkeletonElement,
    AdmCheckbox,
    SocialMediaStory,
    EmailHtmlContent,
    AdmTag,
    AdmBadge,
    AppointmentDetailsItem,
    AdmButton,
    AdmFormItem,
    AdmIcon,
    AdmInput,
  },

  mixins: [datetime],

  props: {
    link: {
      type: String,
      required: true,
    },
    shortLink: {
      type: String,
      default: null,
    },
    back: {
      type: Function,
      default: () => {}
    },
    qrCodeUri: {
      type: Object,
      default: () => {}
    },
    uuid: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isOneTimeLink: false,
      disabledOneTimeLink: false,
    }
  },

  computed: {
    service () {
      return service
    },
  },

  methods: {
    isShoplazza () {
      return this.$store.state.settings.general.externalIntegration && this.$store.state.settings.general.externalIntegration.source === 'shoplazza'
    },

    copyLink (link) {
      copy(link)

      this.$message({ message: this.$t('code_copied_clipboard'), type: 'success', showClose: true })
    },

    shareViaEmail () {
      window.open(`mailto:?subject=${this.$t('book_your_appointment')}&body=${encodeURIComponent(this.link)}`, 'self')
    },

    shareViaFacebook () {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.link)}`, 'popup', 'width=600,height=600')
    },

    shareViaX () {
      window.open(`https://x.com/intent/tweet?text=${this.$t('book_your_appointment_here')}&url=${encodeURIComponent(this.link)}`);
    },

    shareViaLinkedin () {
      window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.link)}&title=${this.$t('book_your_appointment_here')}`, 'popup', 'width=600,height=600')
    },

    copyIframeCodeToClipboard () {
      copy(this.getEmbedCode())
      this.$message({ message: this.$t('code_copied_clipboard'), type: 'success', showClose: true })
    },

    getEmbedCode () {
      return getIframeCode(
        this.uuid ? `?t=s&uuid=${this.uuid}` : '',
        this.$store.getters['settings/getBookingWebsiteDomainData'](),
        this.$store.getters['settings/getSettingByName']('languagePublic', 'customize'),
        this.$store.state.shareBookingForm.queryParams.employee ? this.$store.state.shareBookingForm.queryParams.employee.slug : '',
        false
      )
    },

    getFormattedDateRangeLabel (range) {
      return this.formatDate(range[0]) + ' - ' + this.formatDate(range[1])
    },

    copyQRCodeLink () {
      copy(this.link)
      this.$message({ message: this.$t('link_copied_clipboard'), type: 'success', showClose: true })
    },

    copyEmailHtmlContent () {
      copyElementToClipboard(document.getElementById('email-html-content'))
      this.$message({ message: this.$t('copied_clipboard'), type: 'success', showClose: true })
    },

    downloadQRCodeImage () {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([this.qrCodeUri.svg], { type: 'image/svg+xml' }))
      link.download = 'appointment_qr_code'
      link.click()
    },

    isAppointmentsDetailsVisible () {
      const { queryParams } = this.$store.state.shareBookingForm;
      let showDetails = false

      for (const [key, value] of Object.entries(queryParams)) {
        if (value && !Array.isArray(value)) {
          showDetails = true
          break;
        }

        if (key === 'dateRange' && Array.isArray(value)) {
          showDetails = true
          break;
        }

        if (key === 'timeslots' && Array.isArray(value) && value.length > 0) {
          const { date, time } = value[0];
          if (date !== null && time !== null) {
            showDetails = true;
            break;
          }
        }
      }

      return showDetails
    },

    setIsOneTimeLink (value) {
      this.disabledOneTimeLink = true
      this.$http.post('/api/v1/booking-links/share-link/one-time-link',
        { uuid: this.uuid, isOneTimeLink: value }
      ).then(() => {
        if (this.isOneTimeLink) {
          this.$message({
            message: this.$t('share_link_expire_set'),
            type: 'success',
            showClose: true
          })
        }
      }).finally(() => {
        this.disabledOneTimeLink = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.share-appointment {
  &__iframe {
    margin-bottom: 0;
  }

  &__email {
    &__title {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: $shade-900;
      margin-bottom: 8px;
    }

    &__container {
      gap: 8px;
      border: 1px solid $shade-300;
      border-radius: 4px;
      margin-bottom: 16px;
      max-width: 100%;
      overflow-y: auto;
      padding: 6px;

      @include phone-up {
        padding: 24px;
      }
    }
  }

  &__copy-link-button {
    display: flex;
    cursor: pointer;
  }

  &__share-buttons {
    margin-bottom: 0;

    &__title {
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    &__buttons {
      margin: -6px;
      display: flex;

      @include phone-down {
        flex-direction: column;
        align-items: flex-start;
      }

      & > button {
        margin: 6px;
      }
    }
  }

  &__details {
    margin-top: 16px;

    &__label {
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 8px;
    }

    &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      background: var(--primary-200);
      border: 1px solid var(--primary-400);
      border-radius: 4px;
      padding: 16px 16px 8px;

      &__date {
        color: $shade-900;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }

      &__timeslots {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        margin-bottom: 8px;
      }

      &__extra {
        display: flex;
        align-items: center;

        &__item {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 220px;
          margin-right: 4px;
        }
      }
    }
  }

  // QR code
  &__qr-code {
    &__content {

      &__title {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: $shade-900;
      }

      &__generated-code {
        padding: 24px 0;
        gap: 8px;
        max-width: 600px;
        height: 304px;
        background: var(--primary-400);
        margin-bottom: 12px;
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 256px;
        }
      }

      &__actions {
        display: flex;
        align-items: flex-start;
        column-gap: 24px;
        row-gap: 16px;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
